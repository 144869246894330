import React from 'react';
import Moment from 'react-moment';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck, faRedo } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import { DataTable, FormCheckField } from '../../components';
import APIClient from '../../services/APIClient';
import { User } from '../../types/model';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { DataTableColumnProps, ExportConfigColumn } from '../../types/dataTable';

export default function UsuarioList() {
  useDocumentTitle('Usuarios');
  const { addToast } = useToasts();

  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [users, setUsers] = React.useState<User[]>([]);
  const [backupUsers, setBackupUsers] = React.useState<User[]>([]);

  React.useEffect(() => {
    loadUsuarios();
  }, []);

  async function handleResetPasswordClick(event: React.MouseEvent<HTMLButtonElement>) {
    const { value: usuarioId } = event.currentTarget;

    const confirm = window.confirm(
      `¿Está seguro que desea reiniciar la contraseña del Usuario #${usuarioId}?`,
    );

    if (confirm) {
      try {
        const resetResponse = await APIClient.post(`/usuarios/${usuarioId}/reset-password`, {});
        addToast(
          <p>
            Se ha generado automáticamente la contraseña:
            <br />
            <strong>{resetResponse.data.data.newPassword}</strong>
          </p>,
          {
            appearance: 'success',
          },
        );
      } catch (err) {
        console.error(err);
        addToast(`Ocurrió un error: "${(err as Error).message}"`, {
          appearance: 'error',
        });
      }
    }
  }

  function handleHideInactiveChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;

    let usersToShow: User[] = [];
    let auxBackupUsers: User[] = [];

    if (checked) {
      auxBackupUsers = users;
      usersToShow = users.filter((user) => user.isActive === true);
    } else {
      auxBackupUsers = [];
      usersToShow = backupUsers;
    }

    setUsers(usersToShow);
    setBackupUsers(auxBackupUsers);
  }

  async function loadUsuarios() {
    try {
      const res = await APIClient.get('/usuarios');
      setUsers(res.data.data);
      setIsDataLoading(false);
    } catch (err) {
      console.error(err);
      addToast(`Ocurrió un error: "${(err as Error).message}"`, {
        appearance: 'error',
      });
      setIsDataLoading(false);
    }
  }

  const columns: DataTableColumnProps[] = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'username',
      text: 'Username / E-mail',
      sort: true,
      filterValue: (_cell, row) => `${row.username} ${row.emailAddress}`,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (cellContent, row) => (
        <>
          {cellContent}
          <br />
          <a href={`mailto:${row.emailAddress}`}>{row.emailAddress}</a>
        </>
      ),
    },
    {
      dataField: 'firstName',
      text: 'Nombre',
      sort: true,
      filterValue: (_cell, row) => `${row.firstName} ${row.lastName}`,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (_cellContent, row) => <>{`${row.firstName} ${row.lastName}`}</>,
    },
    {
      dataField: 'perfil.Cliente.razonSocial',
      text: 'Cliente',
      sort: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (_cellContent, row) =>
        row.perfil && row.perfil.Cliente && row.perfil.Cliente.razonSocial,
    },
    {
      dataField: 'lastLogin',
      text: 'Último acceso',
      sort: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (cellContent) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
    },
    {
      dataField: 'isActive',
      text: 'Activo',
      sort: true,
      align: 'center',
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (_cellContent, row) => (
        <>{row.isActive && <FontAwesomeIcon icon={faCheck} fixedWidth />}</>
      ),
    },
    {
      dataField: 'isSuperAdmin',
      text: 'Admin',
      sort: true,
      align: 'center',
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (_cellContent, row) =>
        row.isSuperAdmin && <FontAwesomeIcon icon={faCheck} fixedWidth />,
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: '',
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (_cellContent, row) => (
        <ButtonToolbar>
          <ButtonGroup>
            <LinkContainer to={`/usuarios/${row.id}`}>
              <Button size="sm" variant="outline-primary" title="Editar">
                <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
              </Button>
            </LinkContainer>
            <Button
              size="sm"
              variant="outline-primary"
              title="Resetear contraseña"
              value={row.id}
              onClick={handleResetPasswordClick}>
              <FontAwesomeIcon icon={faRedo} fixedWidth size="xs" />
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      ),
    },
  ];

  /** Columns for export */
  const exportColumns: ExportConfigColumn[] = [
    {
      fieldName: 'id',
      title: 'ID',
    },
    {
      fieldName: 'username',
      title: 'Username',
    },
    {
      fieldName: 'emailAddress',
      title: 'E-mail',
    },
    {
      fieldName: 'perfil.Cliente.razonSocial',
      title: 'Cliente',
    },
    {
      fieldName: 'isActive',
      title: 'Activo',
      parseBoolean: true,
    },
    {
      fieldName: 'isSuperAdmin',
      title: 'Admin',
      parseBoolean: true,
    },
  ];

  return (
    <div>
      <h1 className="page-title">Usuarios</h1>
      <FormCheckField
        id="user-hide-inactive"
        label="Ocultar inactivos"
        onChange={handleHideInactiveChange}
      />
      <DataTable
        columns={columns}
        data={users}
        isDataLoading={isDataLoading}
        keyField="id"
        addButton="/usuarios/nuevo"
        exportConfig={{
          exportURL: '/usuarios/export.xlsx',
          columns: exportColumns,
        }}
      />
    </div>
  );
}
