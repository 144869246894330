import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faMapMarkerAlt,
  faRobot,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Moment from 'react-moment';
import moment from 'moment';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import {
  DataTable,
  EntityEditForm,
  FormCheckField,
  FormInputField,
  FormSelectField,
  FormSwitch,
  Loading,
} from '../../components';
import { isObjectEmpty } from '../../utils';
import { StatusCode } from '../../constants';
import { withRouter } from '../withRouter';
import OrderAIExecutionModal from './OrderAIExecutionModal';
import { formatAmount } from '../Utils';
import FormSelectCustom from '../../components/FormSelectCustom';

class OrderEdit extends React.Component {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      productsList: [],
      Items: [],
      ItemsToDelete: [],
      entity: {},
      isDataLoading: true,
      isItemsModified: false,
      isEditable: false,
      listasPrecios: [],
      formasDePago: [],
      selectedItems: [],
      selectedListaPrecioId: 0,
      puntosEntrega: [],
      selectedItem: {},
      showAddModal: false,
      showListaPrecioModal: false,
      showDeleteModal: false,
      updatedItems: [],
      vendedores: [],
      isAIModalShow: false,
      isLoadingCopyText: false,
      deliveryAddress: 'No hay punto de entrega.',
    };
  }

  /**
   * If row is selected, then add to selectedItems array and save state.
   * @param  {string} oldValue - old cell value
   * @param  {string} newValue - new cell value
   * @param  {Object} row - entire row object
   */
  afterSaveSelectedItem = (oldValue, newValue, row) => {
    if (row.isSelected) {
      this.setState((prevState) => {
        const selectedItems = [...prevState.selectedItems];
        const selectedItem = {
          Product: row,
          solicitudId: this.state.id,
          productId: row.id,
          precioTotal: row.precio,
          posicion: this.findMaxPosicion(prevState.items),
          listaPrecioId: this.state.entity.listaPrecioId,
          formaPagoId: this.state.entity.formaPagoId,
          precio: row.precio,
          cantidad: 1,
          entregado: 0,
        };
        selectedItems.push(selectedItem);
        return { selectedItems };
      });
    }
  };

  /**
   * Save changes after changing prices on Items.
   * @param  {string} oldValue - old cell value
   * @param  {string} newValue - new cell value
   * @param  {Object} item - entire item object
   */
  afterSaveUpdatedItem = (oldValue, newValue, item) => {
    const updatedItems = [...this.state.updatedItems];
    if (item.precio !== null) {
      const { precio, cantidad } = item;
      if (cantidad) {
        item.precioTotal = (parseFloat(precio) * cantidad).toFixed(2);
      } else {
        item.precioTotal = parseFloat(precio);
      }
    }
    let existingUpdDet = updatedItems.find((updatedItem) => updatedItem.id === item.id);
    if (existingUpdDet === undefined) {
      updatedItems.push(item);
    } else {
      existingUpdDet = item;
    }
    this.setState((prevState) => ({
      ...prevState,
      updatedItems,
      isItemsModified: true,
    }));
  };

  /**
   * Render products modal.
   * @return {JSX.Element} productsList modal element
   */
  renderProductsListModal = () => {
    const { productsList, isDataLoading, showAddModal } = this.state;

    const columns = [
      {
        dataField: 'erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => (
          <span>
            {row.erpCodigo}
            <br />
            <small title="EAN">{row.codigoEan13}</small>
          </span>
        ),
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'marca.descripcion',
        text: 'Marca',
        sort: true,
        editable: false,
      },
      {
        dataField: 'productCategory2.description',
        text: 'Categoría 2',
        sort: true,
        editable: false,
      },
      {
        dataField: 'productCategory1.description',
        text: 'Categoría 1',
        sort: true,
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: true,
        formatter: (cellContent, row) => {
          return cellContent ? (
            formatAmount(cellContent)
          ) : (
            <span className="text-danger font-weight-bold">No tiene precio.</span>
          );
        },
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      clickToSelect: true,
      hideSelectAll: true,
      onSelect: this.onSelectProduct,
    };

    return (
      <Modal size="xl" show={showAddModal} onHide={() => this.setState({ showAddModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Productos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {productsList !== undefined && !isObjectEmpty(productsList) && (
            <DataTable
              isDataLoading={isDataLoading}
              selectRow={selectRowProps}
              columns={columns}
              data={productsList || []}
              keyField="id"
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                autoSelectText: true,
                afterSaveCell: this.afterSaveSelectedItem,
              })}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.saveSelectedItems}>
            Listo
          </Button>
          <Button variant="secondary" onClick={() => this.setState({ showAddModal: false })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /**
   * Render modal to change Lista Precio.
   * @returns {JSX.Element} confirmation modal
   */
  renderChangeListaPrecioModal = () => (
    <Modal
      size="md"
      show={this.state.showListaPrecioModal}
      onHide={() => this.setState({ showListaPrecioModal: false })}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar ítem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea cambiar la lista de precios?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.handleListaPrecioChange}>
          Confirmar
        </Button>
        <Button variant="secondary" onClick={() => this.setState({ showListaPrecioModal: false })}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  /**
   * Gets max posicion value and returns value incresead by 1.
   * @param  {Object[]} Items - array de objetos item
   * @return {int} nextPosicion - max value +1.
   */
  findMaxPosicion = (array) => {
    const { selectedItems } = this.state;
    let nextPosicion = 0;
    array.forEach((data) => {
      const { posicion } = data;
      if (posicion >= nextPosicion) {
        nextPosicion = posicion + 1;
      }
    });
    nextPosicion += selectedItems.length + 1;
    return nextPosicion;
  };

  /**
   * Get new lista de precio prices and save to state.
   */
  handleListaPrecioChange = async () => {
    const { selectedListaPrecioId } = this.state;
    const newListaPrecio = await APIClient.get(`/listas-precio/${selectedListaPrecioId}`);
    const itemsPrecios = newListaPrecio.data.data.Items;
    this.setState((prevState) => {
      const Items = [...prevState.Items];
      const updatedItems = Items.map((item) => {
        const itemPreciosDet = itemsPrecios.find(
          (detPrecios) => detPrecios.productId === item.productId,
        );
        if (itemPreciosDet) {
          return {
            ...item,
            precioTotal: parseFloat(itemPreciosDet.precio * item.cantidad).toFixed(2),
            precio: itemPreciosDet.precio,
          };
        }
        delete item.precio;
        delete item.precioTotal;
        return { ...item };
      });
      return { Items: updatedItems, showListaPrecioModal: false, isDataLoading: false };
    });
  };

  calculateTotals = (Items) => {
    let total = 0;

    if (Items.length > 0) {
      Items.forEach((det) => {
        total += det.precio * det.cantidad;
      });
    }
    return total;
  };

  /**
   * Remove deleted item from Items array. If it's not new, add to ItemsToDelete. Save to state.
   */
  onConfirmDelete = () => {
    this.setState((prevState) => {
      const ItemsToDelete = [...prevState.ItemsToDelete];
      const updatedItems = [...prevState.updatedItems];
      const Items = [...prevState.Items];
      const selectedItem = { ...prevState.selectedItem };

      if (prevState.entity.Items.length > 0) {
        const isDeletedItemNew = prevState.entity.Items.find((item) => item.id === selectedItem.id);
        if (isDeletedItemNew === undefined) {
          const updatedIndex = updatedItems.findIndex((item) => item.id === selectedItem.id);
          updatedItems.splice(updatedIndex, 1);
        } else {
          ItemsToDelete.push(selectedItem);
        }
        const selectedIndex = Items.findIndex((item) => item.id === selectedItem.id);
        Items.splice(selectedIndex, 1);
      }

      return {
        Items,
        ItemsToDelete,
        selectedItem: {},
        showDeleteModal: false,
        updatedItems,
        isItemsModified: true,
      };
    });
  };

  /**
   * Show modal and save selectedItem in state.
   * @param  {Object} row - row object from table
   */
  showDeleteConfirmModal = (row) => {
    this.setState({ showDeleteModal: true, selectedItem: row });
  };

  /**
   * Show deleteItem modal to confirm selection to delete.
   * @returns {JSX.Element} confirmation modal
   */
  renderDeleteItemModal = () => (
    <Modal
      size="md"
      show={this.state.showDeleteModal}
      onHide={() => this.setState({ showDeleteModal: false })}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar ítem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar este ítem?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.onConfirmDelete}>
          Eliminar
        </Button>
        <Button
          variant="secondary"
          onClick={() => this.setState({ showDeleteModal: false, selectedItem: {} })}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  /**
   * Copy Items and selectedItems from state and merge them.
   */
  ///////////////// SAVE Items AGREGAR EN EDIT ///////////////////
  saveSelectedItems = () => {
    this.setState((prevState) => {
      const newItems = [...prevState.selectedItems];
      const selectedItems = newItems.map((item) => {
        if (item.Product.isSelected === true) {
          return item;
        }
        return null;
      });
      return {
        Items: [...prevState.Items, ...selectedItems],
        selectedItems: [],
        updatedItems: [...prevState.updatedItems, ...prevState.selectedItems],
        showAddModal: false,
        isItemsModified: true,
      };
    });
  };

  /**
   * Get products, add precio and isSelected properties to all objects in array. Then save state.
   */
  showAddItemModal = async () => {
    const { Items, entity } = this.state;

    const itemsProductIds = Items.map((item) => item.productId);
    try {
      const productsListRes = await APIClient.get(`/products?filter[id][notIn]=${itemsProductIds}`);
      const productsList = productsListRes.data.data;
      const productsPrices = await APIClient.get(`/listas-precio/${entity.listaPrecioId}`);

      const itemsPrecio = productsPrices.data.data.detalles; // This only detalles survived, TODO change ListaPrecio as this WHOLE file
      productsList.forEach((product) => {
        product.precio = 0;
        for (let i = 0; i < itemsPrecio.length; i++) {
          if (itemsPrecio[i].productId === product.id) {
            product.precio = itemsPrecio[i].precio;
            product.isSelected = false;
          }
        }
      });

      this.setState({ showAddModal: true, isDataLoading: false, productsList });
    } catch (err) {
      console.error('Ocurrió un error al traer los precios de los productos!', err);
    }
  };

  /**
   * Show listaPrecio modal to confirm price changes and save selectedListaPrecioId in state.
   * @param  {Object} event - event object
   */
  onChangeListaPrecio = (e) => {
    this.setState({
      showListaPrecioModal: true,
      selectedListaPrecioId: parseInt(e.target.value),
      isDataLoading: true,
    });
  };

  /**
   * Get data for form and save to state.
   */
  onLoadForm = async () => {
    try {
      const listaPrecioResponse = await APIClient.get('/listas-precio');
      const vendedorResponse = await APIClient.get('/agents');
      const formasDePagoResponse = await APIClient.get('/formas-pago');
      formasDePagoResponse.data.data.unshift({
        description: 'No tiene ninguna forma de pago seleccionada',
      });

      this.setState({
        isDataLoading: false,
        listasPrecios: listaPrecioResponse.data.data,
        formasDePago: formasDePagoResponse.data.data,
        vendedores: vendedorResponse.data.data,
      });
    } catch (error) {
      console.error('Cargando información del formulario.', error);
      this.setState({
        isDataLoading: false,
      });
    }
  };

  selectCustomClientLoadOptionsHandler = async (query) => {
    const clientsRes = await APIClient.get(
      `/clientes?filter[razonSocial][like]=${encodeURIComponent(`%${query}%`)}`,
    );

    const optionsFormatted = clientsRes.data.data.map((data) => ({
      value: data.id,
      label: data.razonSocial,
    }));

    return optionsFormatted;
  };

  selectCustomClientChangeHandler = async (e) => {
    let { entity } = this.state;
    const puntosEntregaRes = await APIClient.get(
      `/clientes/${e.target.value}/puntos-entrega`,
    );

    delete entity?.PuntoEntrega;

    this.setState({
      entity,
      puntosEntrega: puntosEntregaRes.data.data ?? [],
      deliveryAddress: 'No hay punto de entrega',
    });
  };

  selectCustomDeliveryChangeHandler = (e) => {
    const { value, options } = e.target;
    const { entity, puntosEntrega } = this.state;

    delete entity?.PuntoEntrega;

    const deliveryIndex = options.findIndex((point) => point.value === value);

    const deliveryAddress =
      deliveryIndex === -1
        ? 'No hay punto de entrega'
        : `${puntosEntrega[deliveryIndex].localidad},
          ${puntosEntrega[deliveryIndex].calle} 
          ${puntosEntrega[deliveryIndex].numero}`;

    this.setState((prevState) => ({
      ...prevState,
      entity,
      deliveryAddress,
    }));
  };

  generateDeliveryString = (deliveryPoint) => {
    if (deliveryPoint === undefined || deliveryPoint === null) {
      return undefined;
    }

    return `${deliveryPoint.descripcion} - ${deliveryPoint.calle}${
      deliveryPoint.numero !== '00' ? ` ${deliveryPoint.numero}` : ''
    }${deliveryPoint.piso ? ` ${deliveryPoint.piso}` : ''}${
      deliveryPoint.depto ? ` ${deliveryPoint.depto}` : ''
    } ${deliveryPoint.localidad}${
      deliveryPoint.codigoPostal && deliveryPoint.codigoPostal !== '0'
        ? ` cp: ${deliveryPoint.codigoPostal}`
        : ''
    }${deliveryPoint.provincia ? ` ${deliveryPoint.provincia.descripcion}` : ''}`;
  };

  /**
   * Retrieve entity (array), add precioTotal property to each element and save to state.
   */
  onRetrieveEntity = async () => {
    const { id, isAdding } = this.state;
    let { isEditable } = this.state;
    const entityResponse = await APIClient.get(`/orders/${id}`);
    const entity = entityResponse.data.data;
    let puntosEntregaProcessed = [];

    if (entity.Cliente?.id) {
      const puntosEntregaRes = await APIClient.get(
        `/clientes/${entity.Cliente.id}/puntos-entrega`,
      );

      puntosEntregaProcessed = puntosEntregaRes.data.data;
    }

    const Items = [...entity.Items];
    Items.forEach((item) => {
      item.precioTotal = parseFloat((item.cantidad * item.precio).toFixed(2));
      item.precio = parseFloat(item.precio);
    });

    if (
      !isAdding &&
      entity.estadoOrderCodigo.match(
        new RegExp(`${StatusCode.draft}|${StatusCode.deliveredPartial}|${StatusCode.pending}`),
      )
    ) {
      isEditable = true;
    }
    this.setState({ entity, Items, isEditable, puntosEntrega: puntosEntregaProcessed });
    return entity;
  };

  /**
   * Save entity.
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, Items, entity, ItemsToDelete, updatedItems } = this.state;

    const { navigate, toastManager } = this.props;
    const productCodesWithoutPrice = [];

    Items.forEach((item) => {
      if (!item.precio) {
        productCodesWithoutPrice.push(item.Product.erpCodigo);
      }
    });

    // If there are updatedItems, change precioEntrega if necessary.
    if (updatedItems.length > 0) {
      updatedItems.forEach((item) => delete item.Product);

      if (entity.estadoOrderCodigo !== StatusCode.draft) {
        let precioEntrega = 0.0;
        entity.Items.forEach((item) => {
          precioEntrega += item.cantidad * item.precio;
        });
        entityToSave.precioEntrega = precioEntrega.toFixed(2);
      }
      entityToSave.Items = [...updatedItems];
    }

    // If the listaPrecioId has changed, set the new listaPrecioId in every item.
    if (entityToSave.listaPrecioId) {
      if (entityToSave.Items) {
        entityToSave.Items = [...Items, entityToSave.Items];
      }
      entityToSave.Items = [...Items];
      entityToSave.Items.forEach((item, listaPrecioId) => {
        item.listaPrecioId = listaPrecioId;
      });
    }

    if (ItemsToDelete.length > 0) {
      const deletedItemsIds = ItemsToDelete.map((item) => item.id);
      entityToSave.assocToDelete = { Items: [...deletedItemsIds] };
    }

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/orders', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/orders/${id}`, entityToSave);
    }

    toastManager.add(
      `Pedido ${saveResponse.data.data.id} guardado con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => navigate('/pedidos'),
    );
    return saveResponse.data.data;
  };

  prepareToSave = (entityToSave) => {
    const { updatedItems } = this.state;
    entityToSave.Items = updatedItems;

    if (entityToSave.puntoEntregaId === '') {
      entityToSave.puntoEntregaId = null;
    }
    if (entityToSave.clienteId === '') {
      entityToSave.clienteId = null;
    }

    return entityToSave;
  };

  /**
   * Toggle isSelected value from product.
   * @param  {Object} row
   * @param  {boolean} isSelect
   */
  onSelectProduct = (row, isSelect) => {
    const { productsList } = this.state;
    const product = productsList.find((art) => art.id === row.id);
    product.isSelected = isSelect;
    if (product.isSelected) {
      this.setState((prevState) => {
        const { id, entity } = prevState;
        const selectedItems = [...prevState.selectedItems];
        const selectedItem = {
          Product: row,
          orderId: id,
          productId: row.id,
          precioTotal: row.precio,
          posicion: this.findMaxPosicion(prevState.Items),
          listaPrecioId: entity.listaPrecioId,
          formaPagoId: entity.formaPagoId,
          precio: row.precio,
          cantidad: 1,
          entregado: 0,
        };
        selectedItems.push(selectedItem);
        return { ...prevState, selectedItems };
      });
    }
  };

  handleAIExecutionClick = () => {
    console.debug('Click open modal');
    this.setState({ isAIModalShow: true });
  };

  handleAIExecutionModalHide = () => {
    this.setState({ isAIModalShow: false });
  };

  handleCopyTextClick = async (event) => {
    event.preventDefault();
    const { toastManager } = this.props;
    const { id } = this.state;

    try {
      this.setState({ isLoadingCopyText: true });
      const solicitudesRes = await APIClient.get(`/orders/${id}/plain-text`);
      navigator.clipboard.writeText(solicitudesRes.data.data);
      toastManager.add('Pedido copiado al portapapeles.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isLoadingCopyText: false });
    }
  };

  render() {
    const {
      id,
      entity,
      Items,
      isAdding,
      isDataLoading,
      isEditable,
      listasPrecios,
      formasDePago,
      puntosEntrega,
      vendedores,
      isAIModalShow,
      isLoadingCopyText,
      deliveryAddress,
    } = this.state;

    const selectItemRow = {
      align: 'center',
      clickToEdit: true,
      clickToSelect: true,
      hideSelectColumn: true,
      mode: 'checkbox',
    };

    const hours = UIUtils.generateHours();

    const total = this.calculateTotals(Items);

    const columns = [
      {
        dataField: 'Product.erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
      },
      {
        dataField: 'Product.descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'cantidad',
        text: 'Cant.',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: isEditable,
        validator: (newValue, row) => {
          if (newValue < row.entregado) {
            newValue = row.cantidad;
            return {
              valid: false,
              message: 'La cantidad solicitada no puede ser menor a la entregada.',
            };
          }
        },
      },
      {
        dataField: 'entregado',
        text: 'Entreg.',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio/Unid.',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: false,
        formatter: (cellContent, row) => {
          return cellContent ? (
            formatAmount(cellContent)
          ) : (
            <span className="text-danger font-weight-bold">No tiene precio.</span>
          );
        },
      },
      {
        dataField: 'precioTotal',
        text: 'Total',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: false,
        formatter: (cellContent, row) => {
          return formatAmount(cellContent);
        },
      },
      {
        dataField: 'isSelected',
        text: '',
        sort: false,
        hidden: !isEditable,
        align: 'center',
        headerAlign: 'center',
        editable: false,
        formatter: (cell, row, rowIndex) => {
          if (!row || row.entregado > 0 || !isEditable) {
            return;
          }
          return (
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => this.showDeleteConfirmModal(row)}
              fixedWidth
              title="Eliminar ítem"
              size="xs"
            />
          );
        },
        csvExport: false,
      },
    ];

    return (
      <div>
        {this.renderDeleteItemModal()}
        {this.renderProductsListModal()}
        {this.renderChangeListaPrecioModal()}
        <OrderAIExecutionModal
          show={isAIModalShow}
          order={entity}
          onHide={this.handleAIExecutionModalHide}
        />
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          isEditable={isEditable}
          isModified={this.state.isItemsModified}
          prepareToSave={this.prepareToSave}>
          <div className="d-flex flex-row">
            <h1 className="d-flex my-3 me-2">
              {isAdding ? 'Pedido nuevo' : 'Pedido'}
              {entity.codigo === null ? `#${id}` : entity.codigo && `${entity.codigo}`}
              {entity.estadoOrderCodigo && (
                <div className="ms-3">
                  {UIUtils.getSolicitudEstadoBadge(entity.estadoOrderCodigo)}
                </div>
              )}
            </h1>
            <div className="d-flex flex-grow-1 justify-content-end align-items-center">
              {entity.aiExecutionId && (
                <Button onClick={this.handleAIExecutionClick}>
                  <FontAwesomeIcon
                    icon={faRobot}
                    fixedWidth
                    title="Ver información de interpretación"
                  />
                </Button>
              )}
              <Button style={{ marginLeft: '1em'}} onClick={this.handleCopyTextClick} disabled={isLoadingCopyText}>
                {!isLoadingCopyText ? (
                  <>
                    <FontAwesomeIcon
                      icon={faFileAlt}
                      fixedWidth
                      title="Copiar orden simple al portapapeles"
                    />
                    <span>Copiar orden simple</span>
                  </>
                ) : (
                  <Loading className="" />
                )}
              </Button>
            </div>
          </div>
          <Row>
            <Col md={6}>
              <FormSelectCustom
                id="clienteId"
                label="Cliente"
                disabled={!isEditable}
                onLoadOptions={this.selectCustomClientLoadOptionsHandler}
                onChange={this.selectCustomClientChangeHandler}
                selectedOption={{ value: entity?.clienteId, label: entity?.Cliente?.razonSocial }}
              />
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label className="form-label">Fecha de creación</label>
                    <p className="form-control-plaintext">
                      <Moment interval={0} format="DD/MM/YYYY">
                        {entity.createdAt}
                      </Moment>
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <FormInputField
                    id="fechaVencimiento"
                    label="Fecha de Vencimiento"
                    type="date"
                    defaultValue={
                      entity.fechaVencimiento
                        ? moment.utc(entity.fechaVencimiento).format('YYYY-MM-DD')
                        : ''
                    }
                    min={moment().format('YYYY-MM-DD')}
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <FormSelectField
                id="vendedorId"
                label="Ejecutivo comercial"
                defaultValue={entity.vendedorId}
                choices={vendedores}
                choiceIdField="id"
                choiceLabelField="nombre"
                disabled={!isEditable}
                required
              />
            </Col>
            <Col md={4}>
              <FormSelectField
                id="listaPrecioId"
                label="Lista de Precios"
                defaultValue={entity.listaPrecioId}
                choices={listasPrecios}
                choiceIdField="id"
                choiceLabelField="descripcion"
                onChange={this.onChangeListaPrecio}
                disabled={!isEditable}
                required
              />
            </Col>
            <Col md={4}>
              <FormSelectField
                id="formaPagoId"
                label="Forma de Pago"
                defaultValue={entity.formaPagoId}
                choices={formasDePago}
                choiceIdField="id"
                choiceLabelField="description"
                disabled={!isEditable}
                required
              />
            </Col>
            <Col md={4}>
              <FormInputField
                id="facturaTipo"
                label="Tipo de factura"
                defaultValue={entity.facturaTipo}
              />
            </Col>
            <Col md={4}>
              <FormInputField
                id="purchaseOrder"
                label="Orden de compra"
                defaultValue={entity.purchaseOrder}
              />
            </Col>
          </Row>
          {entity.transferencistaName && (
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="form-label">Transferencista</label>
                  <p className="form-control-plaintext">{entity.transferencistaName}</p>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <FormInputField
                id="observaciones"
                label="Observaciones"
                as="textarea"
                defaultValue={entity.observaciones}
                placeholder={!entity.observaciones ? 'No hay observaciones.' : ''}
                disabled={!isEditable}
              />
            </Col>
          </Row>
          <Card>
            <Card.Body>
              <Card.Title>
                <h2 className="d-flex">Entrega</h2>
              </Card.Title>
              <Row>
                <Col md={8}>
                  <Row>
                    <Col md={4}>
                      <FormInputField
                        id="fechaEntrega"
                        type="date"
                        label="Fecha de Entrega:"
                        defaultValue={moment.utc(entity.fechaEntrega).format('YYYY-MM-DD')}
                        min={moment().format('YYYY-MM-DD')}
                        disabled={!isEditable}
                        required
                      />
                    </Col>
                    <Col md={2}>
                      <FormSelectField
                        label="Desde:"
                        /* type="text" */
                        id="horaEntregaDesde"
                        defaultValue={entity.horaEntregaDesde}
                        choices={hours}
                        choiceIdField="hour"
                        choiceLabelField="hour"
                        disabled={!isEditable}
                      />
                    </Col>
                    <Col md={2}>
                      <FormSelectField
                        label="Hasta:"
                        /* type="text" */
                        id="horaEntregaHasta"
                        defaultValue={entity.horaEntregaHasta}
                        choices={hours}
                        choiceIdField="hour"
                        choiceLabelField="hour"
                        disabled={!isEditable}
                      />
                    </Col>
                    <Col md={4}>
                      <div className="form-group">
                        <label className="form-label">Fecha de Confirmación</label>
                        <p className="form-control-plaintext">
                          {entity.fechaConfirmacion ? (
                            <Moment interval={0} format="DD/MM/YYYY">
                              {entity.fechaConfirmacion}
                            </Moment>
                          ) : (
                            '-'
                          )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <FormSelectCustom
                    id="puntoEntregaId"
                    label="Punto de entrega"
                    disabled={!isEditable}
                    onChange={this.selectCustomDeliveryChangeHandler}
                    options={puntosEntrega.map((punto) => ({
                      value: punto.id,
                      label: this.generateDeliveryString(punto),
                    }))}
                    selectedOption={{
                      value: entity?.PuntoEntrega?.id,
                      label: this.generateDeliveryString(entity?.PuntoEntrega),
                    }}
                  />
                  <Row className="text-center ms-2">
                    <p className="text-center font-weight-light">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="me-2"
                        fixedWidth
                        size="xs"
                      />
                      {entity?.PuntoEntrega
                        ? `${entity.PuntoEntrega.localidad}, ${entity.PuntoEntrega.calle} ${entity.PuntoEntrega.numero}`
                        : deliveryAddress
                      }
                    </p>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <h2 className="d-flex my-3">Ítems</h2>
          <Row>
            <Col className="p-3">
              {Items && (
                <DataTable
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                    afterSaveCell: this.afterSaveUpdatedItem,
                  })}
                  selectRow={selectItemRow}
                  columns={columns}
                  data={Items}
                  keyField="posicion"
                  isDataLoading={isDataLoading}
                  addButton={isEditable && this.showAddItemModal}
                  enablePagination={false}
                  total={total}
                  getExportData={() => Items}
                  exportFileName={`pedido ${entity.codigo ? entity.codigo : ''}`}
                  customHeaders={columns.map((col) => col.text)}
                />
              )}
            </Col>
          </Row>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(withRouter(OrderEdit));
